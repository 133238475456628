<template>
  <div class="pbf">
     
     <BrandTabReviews
      :inurl="'/reviews'"     
      :brand-id="props.id"   
      />

  </div>
</template>

<script setup lang="ts">

interface Props {
  id: number
}

const props = defineProps<Props>()

</script>